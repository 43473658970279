body {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.app-container {
    // background-color: rgb(216, 255, 242);
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter,
.fade-enter:active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    // text-transform: uppercase;
}

.fs-7 {
    font-size: 0.75em;
}

.auth-box iframe {
    margin: auto !important;
}