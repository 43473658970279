.auth-container {
    height: 100vh;
}

.auth-box {
    width: 600px;
    margin: 0 auto;
    padding: 30px 15px;
    border-radius: 7px;
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.93);
    box-shadow: rgba(53, 55, 74, 0.09) 0px 0px 6px 0px;
}

@media screen and (max-width: 600px) {
    .auth-box {
        width: 90%;
        margin: 30px auto 0 auto;
    }
}

