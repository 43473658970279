@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@400;500;600&display=swap');
body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }


$primary: #442B48;
$secondary: #8d94ba;
$danger: #FFC09F;
$success: #6A7FDB;
$body-bg: #fff;

@import '~bootstrap/scss/bootstrap.scss';
