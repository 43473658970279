.dimmed {
    opacity: 0.5;
}

.card {
    height: 100%;
}

.highlight-toolbar {
    background-color: #f8f9fa;

    svg {
        cursor: pointer;
    }
}

.name {
    text-decoration: none;
}