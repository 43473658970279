.form-wrapper {
    
}
.page-wrapper {
    margin-top: 85px;
}
.builder-wrapper {
}

.h6 {
    font-size: 10px;
}

.form-group {
    margin-bottom: 10px;
}

label {
    
}

.card {
    margin-bottom: 15px;
}
.card-header h5 button {
    font-weight: 600;
}