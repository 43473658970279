.site-header {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .75);
    padding: 0 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    }
    
    .slim-header {
        display: flex;
        background-size: cover
    }
    
    .logo {
        height: 65px;
        text-align: center;
        width: auto;
        padding: 12.5px 0;
    }
    
    .right-half {
        justify-content: flex-end;
        min-height: 64px;
        display: flex;
        flex: 1 1;
    
        a.nav-link {
            height: 65px;
            padding: 0 15px;
            line-height: 65px;
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 1.8px;
        }
}